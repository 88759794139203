<template>
  <div>
    <v-toolbar flat>
      <v-toolbar-title>登録情報</v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <ValidationObserver ref="observer">
        <ValidationProvider
          v-slot="{ errors, valid }"
          name="登録名"
          rules="required|max:20"
        >
          <v-text-field
            prepend-icon="mdi-card-account-details"
            v-model="name"
            :error-messages="errors"
            :success="valid"
            label="登録名"
            outlined
          ></v-text-field>
        </ValidationProvider>
        <ValidationProvider
          v-slot="{ errors, valid }"
          name="登録アドレス"
          rules="email|max:100"
        >
          <v-text-field
            prepend-icon="mdi-email"
            v-model="email"
            :error-messages="errors"
            :success="email && valid"
            label="登録アドレス"
            outlined
          ></v-text-field>
        </ValidationProvider>
      </ValidationObserver>
    </v-card-text>
    <v-card-actions class="justify-end">
      <v-container>
        <v-row justify="end">
          <v-col sm="6" md="3">
            <general-button
              btn_type="info"
              btn_block
              @click-event="onSubmit"
              :disabled="isProcessing"
              ><template v-slot:icon
                ><v-icon left>mdi-content-save</v-icon></template
              >保存</general-button
            >
          </v-col>
        </v-row>
      </v-container>
    </v-card-actions>
  </div>
</template>

<script>
import { reactive, toRefs, defineComponent } from "@vue/composition-api";
import store from "@/store/index.js";
import { repositoryFactory } from "@/repository/repositoryFactory";

export default defineComponent({
  setup(_, ctx) {
    const UsersRepository = repositoryFactory.get("users");
    const state = reactive({
      id: 0,
      loginId: "",
      name: "",
      email: "",
      isProcessing: false,
    });

    // 初期処理
    const Users = store.getters["users/allState"];
    state.id = Users.id;
    state.loginId = Users.loginid;
    state.name = Users.name;
    state.email = Users.email;

    // 保存処理
    const onSubmit = async () => {
      // 入力チェック
      const isValid = await ctx.refs.observer.validate();
      if (!isValid) {
        // エラー処理
      } else {
        if (!window.confirm("変更を保存してよろしいですか？")) {
          return false;
        }
        // 状態を処理中にしてボタンを非活性化
        state.isProcessing = true;

        // 保存処理(update処理、パラメータはjsonで送る)
        const update_params = {
          id: state.id,
          loginid: state.loginId,
          name: state.name,
          email: state.email,
          is_active: 1,
        };
        await UsersRepository.update(update_params)
          .then((response) => {
            if (response.data) {
              store.dispatch("users/changeUserName", response.data.name);
            }
          })
          .catch((error) => {
            throw (
              "ERROR:UserInfo.vue/onSubmit UsersRepository.update (" +
              error +
              ")"
            );
          });
        state.isProcessing = false;
      }
    };

    return {
      ...toRefs(state),
      onSubmit,
    };
  },
});
</script>
