<template>
  <div>
    <user-account></user-account>
    <user-info></user-info>
  </div>
</template>

<script>
import UserInfo from "./userInfo.vue";
import UserAccount from "./userAccount.vue";

export default {
  components: {
    UserInfo,
    UserAccount,
  },
  setup() {
    return {
      UserInfo,
      UserAccount,
    };
  },
};
</script>
