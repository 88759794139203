<template>
  <div>
    <div class="text-subtitle-2 pa-1 blue--text blue lighten-5">
      ご契約中の店舗名および媒体の情報を一覧で表示しています。
    </div>
    <v-card elevation="0">
      <v-card-title>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="（キーワード検索）"
          single-line
          hide-details
          clearable
          dense
        ></v-text-field>
      </v-card-title>
      <!-- テーブル -->
      <v-data-table
        :headers="headers"
        :items="desserts"
        :search="search"
        item-key="id"
        :mobile-breakpoint="0"
        :items-per-page="50"
        :footer-props="{
          'items-per-page-options': [50, 100, -1],
          'items-per-page-text': '表示件数',
        }"
      >
        <!-- eslint-disable-next-line -->
        <template v-slot:footer.page-text="props">
          {{ props.pageStart }} ～{{ props.pageStop }}件／全{{
            props.itemsLength
          }}件
        </template>
        <!-- eslint-disable-next-line -->
        <template v-slot:item.site_name="{ item }">
          <span
            :style="item.color.length != 0 ? '; color:' + item.color : ''"
            class="text-align-middle"
            >{{ item.site_name }}</span
          >
        </template>
        <!-- eslint-disable-next-line -->
        <template v-slot:item.manage="{ item }">
          <a
            target="_blank"
            :href="item.auth_url"
            style="text-decoration: none"
          >
            <v-icon :color="item.color">mdi-application-cog-outline</v-icon>
          </a>
        </template>
        <template v-slot:no-data> 表示するデータがありません </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { reactive, ref, toRefs, defineComponent } from "@vue/composition-api";
import store from "@/store/index.js";
import setting from "@/common/setting.js";
import { repositoryFactory } from "@/repository/repositoryFactory";

export default defineComponent({
  setup(_, ctx) {
    const UsersRepository = repositoryFactory.get("users");
    const state = reactive({
      search: "",
      sites: [], // 媒体リスト
      dialog: false, // ダイアログ表示用
      headers: [
        {
          text: "店舗名",
          value: "shop_name",
          sortable: false,
          class: "td_shop_name",
        },
        {
          text: "媒体名",
          value: "site_name",
          sortable: false,
          class: "td_site_name",
        },
        { text: "メモ", value: "memo", sortable: false, class: "td_memo" },
        {
          text: "管理画面",
          value: "manage",
          sortable: false,
        },
        {
          text: "ID",
          value: "loginid",
          sortable: true,
        },
        {
          text: "パスワード",
          value: "password",
          sortable: true,
        },
      ],
      desserts: [], // DataTablesレコード格納用
    });

    // 初期化処理
    const init = async () => {
      // ローディング表示
      store.dispatch("loadingIcon/showIcon");
      // 店舗媒体一覧 取得
      const Users = store.getters["users/allState"];
      state.desserts.splice(0);
      await UsersRepository.get_shop_site_all(Users.id)
        .then((response) => {
          if (response.data) {
            Object.keys(response.data).forEach(function (key) {
              const shopSite = response.data[key];
              state.desserts.push({
                "shop_name": shopSite.shop_name,
                "site_name": shopSite.site_name,
                "loginid": shopSite.loginid,
                "password": shopSite.password,
                "auth_url": shopSite.auth_url,
                "memo": JSON.parse(shopSite.config).memo,
                "color": JSON.parse(shopSite.config).color,
                "proxy_server_label": shopSite.proxy_server ? "設定あり" : "-",
              });
            });
          }
        })
        .catch((error) => {
          throw (
            "ERROR:settingShop/component/base.js/init ShopRepository.get_with_prop (" +
            error +
            ")"
          );
        });
      // ローディング非表示
      store.dispatch("loadingIcon/hideIcon");
    };

    init();

    return {
      setting,
      ...toRefs(state),
    };
  },
});
</script>

<style scoped>
::v-deep #color-selector .v-input__append-inner {
  margin-top: 13px;
}
.edit_area {
  cursor: pointer;
}

::v-deep .v-data-table table thead tr th,
::v-deep .v-data-table table tbody tr td {
  padding: 0 6px !important;
}
::v-deep .td_shop_name {
  min-width: 150px;
}
::v-deep .td_site_name {
  min-width: 150px;
}
::v-deep .td_memo {
  min-width: 150px;
}
</style>
