var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"text-subtitle-2 pa-1 blue--text blue lighten-5"},[_vm._v(" ご契約中の店舗名および媒体の情報を一覧で表示しています。 ")]),_c('v-card',{attrs:{"elevation":"0"}},[_c('v-card-title',[_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"（キーワード検索）","single-line":"","hide-details":"","clearable":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.desserts,"search":_vm.search,"item-key":"id","mobile-breakpoint":0,"items-per-page":50,"footer-props":{
        'items-per-page-options': [50, 100, -1],
        'items-per-page-text': '表示件数',
      }},scopedSlots:_vm._u([{key:"footer.page-text",fn:function(props){return [_vm._v(" "+_vm._s(props.pageStart)+" ～"+_vm._s(props.pageStop)+"件／全"+_vm._s(props.itemsLength)+"件 ")]}},{key:"item.site_name",fn:function(ref){
      var item = ref.item;
return [_c('span',{staticClass:"text-align-middle",style:(item.color.length != 0 ? '; color:' + item.color : '')},[_vm._v(_vm._s(item.site_name))])]}},{key:"item.manage",fn:function(ref){
      var item = ref.item;
return [_c('a',{staticStyle:{"text-decoration":"none"},attrs:{"target":"_blank","href":item.auth_url}},[_c('v-icon',{attrs:{"color":item.color}},[_vm._v("mdi-application-cog-outline")])],1)]}},{key:"no-data",fn:function(){return [_vm._v(" 表示するデータがありません ")]},proxy:true}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }