<template>
  <div>
    <v-toolbar flat>
      <v-toolbar-title>アカウント</v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <ValidationObserver ref="observer">
        <ValidationProvider
          v-slot="{ errors, valid }"
          name="ログインID"
          rules="required|alpha_num|max:20"
        >
          <v-text-field
            readonly
            prepend-icon="mdi-account-circle"
            v-model="loginId"
            :error-messages="errors"
            :success="valid"
            label="ログインID [変更不可]"
            outlined
          ></v-text-field>
        </ValidationProvider>
      </ValidationObserver>
    </v-card-text>
    <v-card-actions class="justify-end"> </v-card-actions>
  </div>
</template>

<script>
import { reactive, toRefs, defineComponent } from "@vue/composition-api";
import store from "@/store/index.js";

export default defineComponent({
  setup(_, ctx) {
    const state = reactive({
      loginId: "",
    });

    // 初期化処理(同期処理)
    const init = async () => {
      store.dispatch("loadingIcon/showIcon"); // ローディング表示
      state.loginId = store.getters["users/allState"].loginid;
      store.dispatch("loadingIcon/hideIcon"); // ローディング非表示
    };
    init();

    // 返却オブジェクト定義
    return {
      ...toRefs(state),
    };
  },
});
</script>
