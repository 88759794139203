<template>
  <div>
    <v-tabs v-model="items" class="mb-3">
      <v-tabs-slider></v-tabs-slider>
      <v-tab v-for="tab in tabs" :key="tab.index" class="font-weight-bold">{{
        tab.name
      }}</v-tab>
    </v-tabs>
    <v-tabs-items v-model="items" touchless>
      <v-tab-item v-for="tab in tabs" :key="tab.index">
        <component v-bind:is="tab.component"></component>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import { ref } from "@vue/composition-api";
import { useRouter } from "@/utils";
import store from "@/store/index.js";
import utilFunc from "@/common/utilFunc.js";

import UserComponent from "./user";
import ShopSiteComponent from "./shopSite.vue";

export default {
  components: {
    ShopSiteComponent,
    UserComponent,
  },
  setup(props, ctx) {
    const { router } = useRouter();
    const items = ref(null);
    const tabs = [
      {
        index: 1,
        name: "登録店舗/媒体一覧",
        component: ShopSiteComponent,
      },
      {
        index: 2,
        name: "ユーザー情報",
        component: UserComponent,
      },
    ];

    const init = async () => {
      // 店舗チェック falseの場合ログアウトになる
      const currentShop = store.getters["shops/currentShop"];
      if (!(await utilFunc.checkShop(currentShop.id, router))) return;
    };

    init();

    return {
      items,
      tabs,
    };
  },
};
</script>
